/* inventory buttons */

.inv-conf-wrapper Button {
  background: rgba(212, 38, 27, 0.7);
  color: #ffffff;
  border: transparent;
  width: auto;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 12px;
  letter-spacing: 0.06em;
}

.inv-conf-wrapper Button:hover,
.inv-conf-wrapper Button:focus,
.inv-conf-wrapper Button:active {
  background-color: rgb(163, 0, 0);
  color: white;
}

.inventory-table .elixir-table-wrapper {
  height: 67vh;
  overflow: hidden;
}

.disabled-button {
  background-color: rgb(180, 0, 0) !important;
  color: white;
  border: 1px solid rgb(83, 0, 0) !important;
}
.customer-transaction-tab .nav-link {
    background-color: transparent !important;
    color: rgb(198, 12, 12);
    border-radius: 0 !important;
    border: 2px solid white !important;
    padding-top: 0.05em;
    padding-bottom: 0.05em;
    text-transform: uppercase;
    font-weight: 600;
}

.customer-transaction-tab .nav-link.active,
.customer-transaction-tab .show>.nav-link {
    color: rgb(198, 12, 12) !important;
    border: 2px solid rgb(198, 12, 12) !important;
}

.customer-transaction-tab .nav-link:focus,
.customer-transaction-tab .nav-link:hover {
    color: rgb(198, 12, 12);
}

.code-input {
    display: block;
    position: relative;
}

/* To remove space between text inputs */
.input-group>.input-group-btn:empty {
    width: 0px;
}

.full-width {
    width: 100%;
}

.input-checkbox {
    width: 10px !important;
    height: 1.50em;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.input-checkbox:checked {
    background-color: #D41B2E;
    clip-path: polygon(28% 38%, 41% 53%, 75% 24%, 86% 38%, 40% 78%, 15% 50%);

}

.filter-add-cont {
    width: 99%;
}

.giftcerts-table .elixir-table-wrapper {
  height: 63.5vh;
  overflow: hidden;
}

.gift-cert-form .react-select-container-modal {
    margin: 0;
    width: 100%;
    text-transform: capitalize;
}

.delete-selected-btn {
    width: 150%;
    margin-left: -50%;
}

.add-gift-btn {
    width: 150%;
    margin-left: -100% !important;
}
.del-prompt-header-custom {
    background-color: rgb(213, 19, 19);
}

.del-prompt-icon {
    font-size: 5em;
}

.del-prompt-par {
    text-transform: none;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.del-prompt-subpar {
    text-transform: none;
    margin-bottom: 0;
}

.details-cont {
    border-left: 1px solid #B21309;
    margin: 10%;
    margin-top: 10% !important;
}

.service-details-header {
    font-weight: bolder;
    margin-top: 45%;
}

.modal-detail {
    font-weight: 300 !important;
}

.service-table .elixir-table-wrapper {
  height: 67vh;
  overflow: hidden;
}
.side-navbar {
    width: 280px;
    height: 100%;
    position: fixed;
    margin-left: -300px;

    background-image: linear-gradient(180deg, #D4261B -39.32%, rgba(212, 27, 46, 0.71) 2.18%, rgba(212, 27, 46, 0.26) 60.68%), url("../images/bg/navbar-bg.jpg");
    object-fit: cover;
    background-repeat: repeat;
    background-size: cover;

    transition: 0.5s;
    overflow-x: hidden;

    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.my-4 {
    height: 80px;
    width: auto;
}

.branch-name-nav {
    text-transform: uppercase;
    font-weight: bolder;
    margin-bottom: 10% !important;
    background-color: #000000;
    width: 100%;
    padding: 5%;
}


.side-navbar a {
    text-decoration: none;
}

.active-nav {
    margin-left: 0;
}

.nav-icon {
    width: 40px;
    height: 40px;
}

.nav-elixir-custom .navlink-test {
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    text-align: left;
    padding: 0.5rem 1.5rem;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    line-height: 25px;
}

.nav-elixir-custom .navlink-test:hover {
    background-color: rgba(85, 7, 58, 0.129);
}

.nav-elixir-custom .navlink-selected {
    background-color: #55073A21;
    color: #fff;
    margin: 0;
    border-top: 3px solid rgba(255, 255, 255, 1);
    border-bottom: 3px solid rgba(255, 255, 255, 1);
}

.free-service {
    width: 20%;
}

.customer-balance {
    width: 100%;
    height: auto;
}

.daily-transac {
    margin-top: 6px;
    width: 90%;
    height: auto;
}


/* DROPDOWN */

.manage-dropdown-custom .dropdown-option {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    text-align: left;
    padding-right: 1em;
    padding-left: 6em;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
}

.manage-dropdown-custom .dropdown-option:hover {
    background-color: rgba(85, 7, 58, 0.129);
}

.manage-dropdown-custom .dropdown-option-selected {
    background-color: rgba(85, 7, 58, 0.129);
    color: #fff;
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

/* OTHERS */

.logout-field {
    color: hsl(0, 0%, 7%);
}

.logout-field:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(85, 7, 58, 0.20));
}

.caret-icon {
    font-size: 1.25em;
}


/* Custom Scrollbar */

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* RESPONSIVE CSS STYLES AHEAD */

@media only screen and (min-width: 1000px) {
    .active-cont {
        overflow-x: hidden;
        margin-left: 275px;
        height: 100vh;
    }
}
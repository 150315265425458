/* CUSTOMER INFORMATION (TABLE) BELOW */

.customer-deets tr {
    border-style: hidden;
}

.customer-deets th,
.customer-deets td {
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 0;
}

.customer-deets th {
    text-transform: uppercase;
}

/* CUSTOMER DETAIL ICON STYLES */

.edit-details-icon {
    cursor: pointer;
}

/* CUSTOMER SEARCH STYLES */

.search-trans-custom {
    width: 40% !important;
    margin-bottom: 1rem;
}

/* CUSTOMER TRANSACTION TAB STYLES BELOW */

.customer-transaction-tab .nav-link {
    background-color: transparent !important;
    color: rgb(198, 12, 12);
    border-radius: 0 !important;
    border: 2px solid white !important;
    padding-top: 0.05em;
    padding-bottom: 0.05em;
    text-transform: uppercase;
    font-weight: 600;
}

.customer-transaction-tab .nav-link.active,
.customer-transaction-tab .show>.nav-link {
    color: rgb(198, 12, 12) !important;
    border: 2px solid rgb(198, 12, 12) !important;
}

.customer-transaction-tab .nav-link:focus,
.customer-transaction-tab .nav-link:hover {
    color: rgb(198, 12, 12);
}

.customer-details-table-content {
    height: 45vh;
}

.commission-table .elixir-table-wrapper {
    height: 55vh;
    overflow: hidden;
}
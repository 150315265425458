.cont-section-sales-report .branch-report-row {
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
}

.cont-section-sales-report .branch-report-row .col {
    padding: 0 !important;
}

.report-card div h6 {
    text-align: center;
}

.t-bold {
    font-weight: bold;
}

.branch-report-row .report-card .btn-report-wrapper Button {
    background: rgba(212, 38, 27, 0.2);
    border-radius: 20px;
    width: 140px;
    height: 76px;
    border: none;
}

.branch-report-row .report-card .btn-report-wrapper Button:hover,
.branch-report-row .report-card .btn-report-wrapper Button:focus,
.branch-report-row .report-card .btn-report-wrapper Button:active {
    background-color: rgba(233, 26, 11, 0.412);
}

.branch-report-row .report-card .btn-report-wrapper .net-report-card {
    background: #F16154;
    cursor: default;
}

.cont-section-sales-report .branch-report-row .report-card .btn-report-wrapper Button h6 {
    font-size: 18px;
}

.sales-loader {
    margin-left: 2.7vw;
}


/* table styles exclusively for daily sales report*/

.report-table-content {
    max-height: 60vh;
}

.list-loader {
    margin-left: 30vw;
}

.sales-table .elixir-table-wrapper {
  height: 60vh;
  overflow: hidden;
}
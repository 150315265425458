.suggestions-list {
  background-color: #f2f2f0;
  width: 47%;
  height: fit-content;
  max-height: 50%;
  overflow-y: scroll;
  z-index: 1000;
  position: absolute;
  border-radius: 0.25rem;
  margin-top: -1%;
  margin-left:38%;
}

div.suggestions-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
div.suggestions-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
div.suggestions-list::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 0.25rem;
}

/* Handle on hover */
div.suggestions-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.suggestions-item {
  padding: 1vw;
  border: none;
  background-color: transparent;
  width: 100%;
  text-align: left;
}

.suggestions-item:hover {
  background-color: #ececec;

}
.primary-cont-reports {
    background: #e91a0b69;
    border-radius: 15px;
    margin: 3vh 0 6vh 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 16vh;
    width: 24vw;
    cursor: pointer;
}

.primary-cont-reports:hover {
    background: #f16154d8;
}

.total-net {
    background: #f16154d8 !important;
    cursor: default !important;
}

.primary-data-reports {
    font-family: 'BeVietnamPro-Regular';
    font-weight: 700;
    font-size: 24px;
}

.primary-data-reports span {
    color: #d4261bb3;
    margin-right: 1vw;
}

.secondary-wrapper-reports {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;

    height: 58vh;
    width: 20vw;
}

.secondary-cont-reports {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.secondary-data-reports {
    background: #d312122b;
    border-radius: 15px;
    margin-bottom: 1vh;

    font-family: 'BeVietnamPro-Regular';
    font-weight: 600;
    font-size: 18px;

    display: flex;
    justify-content: center;
    align-items: center;

    height: 22vh;
    width: 9vw;
    cursor: pointer;
}

.secondary-data-reports:hover {
    background: #e91a0b69;
}

.customer-balance-header {
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
}

.customer-balance-wrap {
    width: 71%;
}

.customer-balance-wrap .customer-balance-report-table {
    margin-top: 1vh;
}

.customer-balance-report-table {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customer-balance-report-table .elixir-table-wrapper .elixir-table thead {
    border-top: 1px solid black !important;
}
.paynow-cont-section {
  padding: 3em 2.5em;
}

.zero-opacity{
  opacity: 0;
}

.close-cont {
  margin-bottom: 1.5vh !important;
}

.paynow-cont-section ::placeholder {
  font-family: "BeVietnamPro-Thin";
  text-transform: uppercase;
  opacity: 100% !important;
}


.btn-conf-wrapper .buy-btn {
  font-size: 13px;
}

.summary-item-list {
  max-height: 12em !important;
}

.paynow-cont-section .summary-item-list {
  width: auto;
  height: 15em;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 2px;
  background: rgba(212, 27, 46, 0.15);
  border-radius: 10px;
}

.cont-section .paynow-cont-section {
  padding: 30px;
}

#growth {
  overflow-y: scroll;
}

.list-group-item {
  padding: 0.5rem 0.5rem !important;
  background-color: transparent !important;
  border: none !important;
}

.paynow-cont-section .summary-item-list .item-name p {
  margin: 0;
}

.paynow-cont-section  {
  width: 80%;
}
.summary-item-list{
  width:90%;
}
.item-name{
  width:50%;
}

.item-price{
  width:25%;
}

.item-qty {
  margin-left: 3%;
  margin-right: 2%;
}

[class^="ico-"],
[class*=" ico-"] {
  font: normal 2em/1 Arial, sans-serif;
  display: inline-block;
}

.ico-times:before {
  content: "\2716";
}

.ico-check:before {
  content: "\2714";
}

.close-cont {
  margin-top: -8%;
  margin-bottom: 8%;
  margin-left: -10%;
}

.buttons-pay-now {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 2em;
}

.buttons-pay-now Button {
  margin-top: 0 !important;
}

.table-modal-new{
  width: 10px !important;
}
/* note to self - will clean this later coz i made before knowing bootstrap lol */

.login-screen {
    background: linear-gradient(180deg, rgba(212, 27, 46, 0.036) 116.9%, rgba(215, 145, 0, 0.0914649) 158.4%, rgba(161, 107, 0, 0) 216.9%), url("../../../images/bg/login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}

.login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 80%;
    max-width: 400px;
    height: 500px;
    margin-left: 60%;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 1);
    box-sizing: border-box;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.login-form-header {
    text-align: center;
    flex: 1;
    margin-bottom: 5%;
}

.login-form-header img {
    height: 72px;
    margin-top: 10%;
}

.login-form-header .branch-name {
    text-transform: uppercase;
    margin-bottom: 10%;
    font-weight: bold;
    color: #000000;
}

.login-form-header h4 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.06em;
    margin-top: 0px;
    margin-bottom: 0px;
}

.login-form-header h5 {
    font-weight: 400;
    font-size: 1.05em;
    color: gray;
    margin-bottom: 0px;
}

.login-form-container {
    flex: 1;
}

.form-group {
    padding: 5%;
    margin-bottom: 20%;
}

.username-wrapper {
    margin-bottom: 4%;
}

.password-wrapper {
    margin-bottom: 10%;
}

.pass-input-length {
    padding: 0.375rem 2.5rem 0.375rem 0.75rem !important;
}

.password-icon {
    display: inline;
    float: right;
    margin-right: 4.5%;
    margin-top: -11%;
    cursor: pointer;
    font-size: medium;
}

.forgot-pass-link {
    text-align: right;
}

.forgot-pass-link a {
    color: hsl(4, 77%, 47%);
    font-size: 14px;
    text-align: right;
}

.forgot-pass-link a:hover {
    color: hsl(4, 77%, 38%);
    font-size: 14px;
    text-align: right;
}

.username-wrapper input,
.password-wrapper input {
    border: 1px solid rgba(0, 0, 0, 1);
}

.btn-wrapper Button {
    background-color: hsl(4, 77%, 47%);
    border: none;
}

.btn-wrapper Button:hover,
.btn-wrapper Button:active,
.btn-wrapper Button:focus {
    background-color: hsl(4, 77%, 38%);
}

.error-message {
    font-size: 14px;
    color: red;
    padding: 2%;
    margin: auto;
}

.form-control:focus {
    box-shadow: none !important;
    border-color: black !important;
}
.del-prompt-header-custom {
    background-color: rgb(213, 19, 19);
}

.del-prompt-icon {
    font-size: 5em;
}

.del-prompt-par {
    text-transform: none;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.del-prompt-subpar {
    text-transform: none;
    margin-bottom: 0;
}

.nav-pills .nav-link.active {
    color: rgb(163, 0, 0) !important;
    background: transparent !important;
    border-width: 1px 1px 0 1px;
    border-color: rgb(163, 0, 0);
    border-style: solid;
    /* border: 1px solid rgb(163, 0, 0) !important; */
    border-radius: 0 !important;
}

.nav-link {
    color: rgb(163, 0, 0) !important;
    border-radius: 0 !important;
}

.edit-employee-tabs {
    border-bottom: 1px solid rgb(163, 0, 0);
    margin-bottom: 2em !important;
}

.employee-table .elixir-table-wrapper {
    height: 66vh;
    overflow: hidden;
}

.view-employee-header {
    font-size: x-large;
    font-weight: bold;
    font-style: italic;
    margin: 0 1vw 0 2vw;
}

.grand-total-label {
    font-weight: bold;
    color: rgb(163, 0, 0);
}
/* CSS Here are just files that are common throughout all components */

/* ACTIVE CONT COMMON STYLES */

.cont-section {
    padding: 2rem 2rem;

}

.cont-section-novertpad {
    padding: 0 2em 0 2em;
}

.cont-sect-header {
    font-size: 1.625rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0;
    letter-spacing: 0.06em;
}

.cont-sect-subheader {
    font-size: 1.5em;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
}

/* ELIXIR TABLE COMMON STYLES */

.elixir-table-wrapper .elixir-table {
    --bs-table-striped-bg: hsla(0, 84%, 45%, 0.17);
    overflow-y: scroll !important;
    overflow-x: scroll !important;
}

.elixir-table-wrapper .elixir-table thead {
    border-top: 2px solid black;
}

.elixir-table-wrapper .elixir-table th{
    position: sticky !important;
      top: 0;
}
.table th{
    position: sticky !important;
    top: 0;
    
}


.elixir-table-wrapper .elixir-table th {
    text-transform: uppercase;
    font-size: 14px;
    text-align: left;
    background-color: #ffffff !important;
    border-bottom: 2px solid black;
}

/* BUTTON COMMON STYLES */

/* submit buttons */
.btn-conf-wrapper Button {
    background-color: white;
    color: #a30000;
    border: 1px solid #a30000;
    text-transform: capitalize;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.09em;
    margin-top: 5%;
}

.btn-conf-wrapper Button:hover,
.btn-conf-wrapper Button:focus,
.btn-conf-wrapper Button:active {
    background-color: rgb(163, 0, 0);
    color: white;
    border: 1px solid rgb(163, 0, 0);
}

.btn-conf-wrapper Button:disabled {
    background-color: rgb(191, 191, 191);
    color: white;
    border: 1px solid rgb(147, 147, 147);
}


/* service view buttons */
.srv-conf-wrapper Button {
    background: rgba(212, 38, 27, 0.7);
    color: #ffffff;
    border: transparent;
    width: auto;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 12px;
    letter-spacing: 0.06em;
}

.srv-conf-wrapper Button:hover,
.srv-conf-wrapper Button:focus,
.srv-conf-wrapper Button:active {
    background-color: rgb(163, 0, 0);
    color: white;
}
.left {
    text-transform: capitalize;
    text-align: right !important;
}

/* inventory dropdown button */
.inventory-dropdown {
    display: flex;
    width: 150px;
    background-color: hsla(4, 77%, 57%, 1);
    border-radius: 3px;
    justify-content: right;
    align-items: right;
}

.inventory-dropdown-menu {
    background-color: hsla(4, 77%, 57%, 1) !important;
    width: 150px;
    justify-content: center;
    align-items: center;
}

.test-btn {
    width: 100px;
    background-color: #d4271bcc;
    border-radius: 3px;
    border-color: transparent;
}

.inventory-btn-wrapper Button {
    background-color: rgba(213, 19, 19, 0.29);
    color: rgb(105, 1, 1);
    border-radius: 79px;
    border-style: none;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    width: 12rem;
}

.inventory-btn-wrapper Button:hover,
.inventory-btn-wrapper Button:focus,
.inventory-btn-wrapper Button:active {
    background-color: rgba(213, 19, 19, 0.4);
    color: rgb(105, 1, 1);
}

.inv-icon {
    margin-left: 10%;
    margin-right: 10%;
}

/* cancel buttons */
.cancel-btn-conf-wrapper Button {
    background: rgba(212, 27, 46, 0.1);
    color: rgb(163, 0, 0);
    border: 1px solid transparent;
    text-transform: capitalize;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.09em;
}

.cancel-btn-conf-wrapper Button:hover,
.cancel-btn-conf-wrapper Button:focus,
.cancel-btn-conf-wrapper Button:active {
    background-color: rgb(163, 0, 0);
    color: white;
    border: 1px solid rgb(163, 0, 0);
}

/* COMMON SEARCH FORM STYLES */

.search-indent {
    padding-left: 2.5rem !important;
}

.search-icon {
    display: inline;
    float: left;
    margin-left: 0.75em;
    margin-top: -1.95em;
    cursor: pointer;
    font-size: medium;
}

/* CUSTOMER MODAL (or similar) STYLES BELOW */

/* add dialogClassName="border-radius-20" to all modals */
.border-radius-20 div {
    border-radius: 20px !important;
}

.custom-modal-style {
    font-family: "BeVietnamPro-Regular";
    text-transform: uppercase;
    border-radius: 20px !important;
}

.custom-modal-style .nc-modal-custom-row {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 0.7rem;
    font-weight: bold;
}

.nc-modal-custom-row .nc-modal-custom-input {
    padding: 0rem 0.75rem;
    background-color: hsla(0, 84%, 45%, 0.17);
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.31);
}

.nc-text-area-input {
    padding: 0rem 0.75rem;
    background-color: hsla(0, 84%, 45%, 0.17);
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.31);
    margin-left: 6.5%;
    width: 93.5%;
}

.nc-modal-custom-row .nc-modal-custom-input::placeholder {
    opacity: 50%;
}

.custom-modal-header {
    border-bottom: none !important;
    display: flex;
    justify-content: center;
}

.custom-modal-body-title {
    font-size: x-large;
    font-weight: bold;
    font-style: italic;
    margin-top: 10px;

    margin-left: auto;
    margin-right: auto;
}

.btn-close {
    margin-left: 0 !important;
}

.custom-modal-body-subtitle {
    font-size: x-large;
    font-weight: bold;
}

.custom-modal-body-description {
    font-style: normal;
    font-size: 15px;
    font-weight: 200;
    letter-spacing: 0.06em;
    color: #000000;
    margin: 0 0 5% 0;
}

.custom-modal-vertline-border {
    border-left: 1px solid rgb(161, 0, 0);
}

/* added style */

.tcenter {
    text-align: center !important;
}

.modal-commission {
    width: 25% !important;
}
.modal-commission-new {
    width: 29% !important;
}

td {
    text-transform: capitalize;
    text-align: left;
    border-bottom-width: 0px !important;
}



/* bootstrap override modals */
.modal-content {
    border-radius: 1.25rem !important;
    border: 1px solid #B31309 !important;
    width: 110%;
}

.modal-header {
    border-top-left-radius: 1.25rem !important;
    border-top-right-radius: 1.25rem !important;
}

/* scrollbar styles */
::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background: rgba(196, 196, 196, 0.63) !important;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background: #BBB6B6 !important;
    border-radius: 5px;
}
/* 
table {
    border-collapse: collapse;
} */
/* thead {
    background-color: #EFEFEF;
}
thead, tbody {
    display: block;
}
tbody {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 100vh;
}
td, th {
    min-width: 262px;
    height: 50px;
    overflow:hidden;
    text-overflow: ellipsis;

} */
.container{
width:100%;
}

/*  fixed table header & footer 
    and scrollable table body */

 .elixir-table-wrapper .fixed-table{
    
    position: sticky !important;
    top: 0;
}
/*
thead, tfoot {
    display:table;
    width:100%;
}
*/

tbody {
    max-height: 45vh;
    overflow: auto;
}

.report-table tbody {
    max-height: 60vh !important;
    overflow: auto !important;
}

.dropdown-input {
    background-color: #D4261BB2;
    color: #ffffff;
    border: none;
    border-radius: 0.2em;
    padding: 1%;
}

/* FILTER */
.filter-cont {
    margin: 2% 0% 2% 3%;
}

.filter-cont-2 {
    margin: 2% 0% 2% 6%;
}

.filter-date-picker {
    height: 2rem;

    margin-left: 1%;
    margin-right: 2%;
    padding: 0.5%;

    border: none;
    border-radius: 0.3em;

    background-color: #D4261BE5;
    color: #ffffff;
    outline: none;
}

.filter-dropdown-input {
    height: 2rem;
    margin-left: 1%;

    background-color: #D4261BE5;
    color: white;

    border: 1px solid #D4261BE5;
    border-radius: 0.3em;
    outline: none;
}

.filter-dropdown-input .select-items div {
    background-color: #991e15e5 !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    width: auto
}

.filter-label-row1, .filter-label {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1%;
}

.table-max {
    display: block;
    height: 35vh !important;
    /* overflow-y: scroll !important ;
    overflow-x: scroll !important ; */
    border-bottom: "2px solid #000000";
    border-top: "2px solid #000000"
}

.table-max thead th{
    position: sticky !important;
    top: 0;
    border-bottom: 2px solid #000000;
    border-top: 2px solid #000000
}

.table-max-2 {
    display: block;
    height: auto !important;
    overflow: auto !important; 
    /* overflow-y: scroll !important ; */
    /* overflow-x: scroll !important ; */
    border-bottom: 2px solid #000000;
    border-top: 2px solid #000000
}

.table-max-2 thead th{
    position: sticky !important;
    top: 0;
    border-bottom: 2px solid #000000;
    border-top: 2px solid #000000;
    background-color: #ffffff !important;
}

.tableFixHead          { 
    display: block;
    overflow: auto; 
    height: 100px;
    text-transform: uppercase;
    font-size: 14px;
    text-align: left; 
}
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

.th {
    top: 0;
    position: sticky;
    background: #e5d2f1;
    color: black;
 }
.not{
    /* overflow-y: hidden !important; */
    position: fixed !important;
}
.grand-total {
    margin-left: 1em
}

.grand-total-row {
    border-bottom: 1px solid white;
}

.sticky {
    position: fixed !important;
    top: 0;
}

.no-data-prompt {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.7vw;
}

.no-data-found {
    display: flex;
    justify-content: center;
}

.react-select-container {
    width: 25vw;
}

.react-select__control {
    min-height: 2rem !important;
    margin-left: 10%;
    background-color: #D4261BE5 !important;
    color: #ffffff;
    border: 1px solid #D4261BE5;
    border-radius: 0.3em !important;
    padding: 0 0.5% 0 1%;
}

.react-select__control:hover {
    border: none !important
}

.react-select__option {
    padding: 0 0 0 1vw !important;
    margin: 0 !important;
}

.react-select__option,
.react-select__menu {
    background-color: #D4261BE5 !important;
    text-transform: capitalize;
    color: #ffffff;
    font-weight: lighter;
}

.react-select__menu {
    margin: 0 0 0 2vw !important;
    max-width: 24vw;
    padding: 0 !important;
}

.react-select__menu-list {
    max-height: 24vh !important;
}

.react-select__placeholder,
.react-select__dropdown-indicator,
.react-select__single-value {
    color: white !important;
    font-weight: lighter;
}

.react-select__indicator-separator {
    width: 0 !important;
}

.react-select__input-container,
.react-select__value-container,
.react-select__indicator {
    margin: 0 !important;
    padding: 0 !important;
    color: white !important;
    font-weight: lighter;
}

.react-select__control--is-focused,
.react-select__control--menu-is-open {
    border-color: none !important;
    box-shadow: none !important;
}

/* pagination */
.MuiPagination-ul {
    justify-content: center;
    margin: 1vh 0 2vh 0 !important;
}

.MuiPaginationItem-page.Mui-selected {
    background-color: hsla(0, 84%, 45%, 0.17) !important;
}

.modal-loader {
    align-self: center !important;
}

/* react-select */
.react-select-container-modal {
    margin: auto;
    width: 80%;
    color: #212529 !important;
    font-family: 'BeVietnamPro-Regular' !important;
    font-size: 0.9rem;
    font-weight: lighter;
    border: none !important;
}

.react-select-container-modal-new {
    margin: auto;
    width: 100%;
    color: #212529 !important;
    font-family: 'BeVietnamPro-Regular' !important;
    font-size: 0.9rem;
    font-weight: lighter;
    border: none !important;
}


.dropdown-modal__option,
.dropdown-modal__control,
.dropdown-modal__menu,
.dropdown-modal__value-container,
.dropdown-modal__value-container--has-value,
.dropdown-modal__single-value,
.dropdown-modal__indicators {
    background-color: #F8D7D7 !important;
}

.dropdown-modal__indicator,
.dropdown-modal__placeholder,
.dropdown-modal__dropdown-indicator,
.dropdown-modal__single-value {
    color: #212529 !important;
}

.dropdown-modal__option--is-selected,
.dropdown-modal__option--hovered {
    background: #ffb8b8 !important;
    color: #a30000 !important;
}

.dropdown-modal__control {
    border-radius: 0 !important;
    border: 1px solid #b1b4ac !important;
    height: 1rem !important;
    padding: 0 !important;
}

.dropdown-modal__input-container {
    margin: 0 !important;
    padding: 0 !important;
}

.dropdown-modal__dropdown-indicator,
.dropdown-modal__indicator {
    border: none !important;
    /* height:0.1rem !important; */
}

td.vcenter {
    vertical-align: middle !important;
    padding: 0.5rem 0rem !important;
}

.input-quantity {
    height: 2.35rem !important;
}

.buy-modal-table{
    z-index: 10000;
}

/* no data prompt */
.no-data-prompt-container,
.no-data-prompt-customers {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
}

.no-data-prompt-container span,
.no-data-prompt-customers span {
    color: #747474;
    font-weight: 500;

    margin-top: 2vh;
    text-align: center;
}

.no-data-prompt-container {
    top: 10vh;
}

.no-data-img {
    height: 25vh;
}



.new-table {
    /* border-top: 2px solid #000000 !important; */
    width: 100% !important;
}
.new-tbody {
    display: table;
    width: 300px !important;
    table-layout: fixed;/* even columns width , fix width of table too*/
}
.new-tr-td-th {
    display: table;
    width: 110px !important;
    table-layout: fixed;/* even columns width , fix width of table too*/
}

.text-align{
    text-align: right !important;
}

.new-line-hr{
    height: 2px !important;
    color: #000000 !important;
}

.new-service-width{
    width: 40vw !important;
    text-align: left !important;
}

.new-service-right{
    text-align: right !important;
    padding-right: 20vw !important;
}

/* CUSTOMER INFORMATION (TABLE) BELOW */

.customer-deets tr {
    border-style: hidden;
}

.customer-deets th,
.customer-deets td {
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 0;
}

.customer-deets th {
    text-transform: uppercase;
}

/* CUSTOMER DETAIL ICON STYLES */

.edit-details-icon {
    cursor: pointer;
}

/* CUSTOMER SEARCH STYLES */

.search-trans-custom {
    width: 40% !important;
    margin-bottom: 1rem;
}

/* CUSTOMER TRANSACTION TAB STYLES BELOW */

.customer-transaction-tab .nav-link {
    background-color: transparent !important;
    color: rgb(198, 12, 12);
    padding-top: 0.05em;
    padding-bottom: 0.05em;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
}

.customer-transaction-tab .nav-link.active,
.customer-transaction-tab .show>.nav-link {
    color: rgb(198, 12, 12) !important;
    border: 2px solid rgb(198, 12, 12) !important;
}

.customer-transaction-tab .nav-link:focus,
.customer-transaction-tab .nav-link:hover {
    color: rgb(198, 12, 12);
}

.in-line {
    display: inline-block;
}

.mtn-1 {
    margin-top: -1% !important;
}

.remove-filter-btn {
    padding-left: 2%;
}

.transaction-button button {
    position: absolute;
    bottom: 2vh;
    right: 2vw;
}

.loader-cont {
    margin-top: 20%;
}

.delete-customer Button {
    color: #a30000;
    border: 1px solid #a30000;
    background: white;

    font-size: small;
    text-transform: uppercase;
}

.delete-customer Button:hover {
    background: #a30000;
    color: white;
    border: 1px solid #a30000;
}
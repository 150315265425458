.item-detail-expand {
    height: 100vh;
}

.item-detail-custom {
    margin-left: 10%;
    margin-right: 10%;
}

.inventory-btn-wrapper Button {
    background-color: rgba(213, 19, 19, 0.29);
    color: rgb(105, 1, 1);
    border-radius: 79px;
    border-style: none;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: left;
    white-space: nowrap;
    width: 10rem;
}

.inventory-btn-wrapper Button:hover,
.inventory-btn-wrapper Button:focus,
.inventory-btn-wrapper Button:active {
    background-color: rgba(213, 19, 19, 0.4);
    color: rgb(105, 1, 1);
}

.inv-icon {
    margin-left: 10%;
    margin-right: 10%;
}

.close-inventory-btn {
    background: #C24B58 !important;
    border: 1px solid #FFFFFF !important;
    color: #FFFFFF !important;
}
.branch-screen {
    background: linear-gradient(180deg, rgba(212, 27, 46, 0.036) 116.9%, rgba(215, 145, 0, 0.0914649) 158.4%, rgba(161, 107, 0, 0) 216.9%), url("../../../images/bg/login.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
}


.branch-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    align-content: flex-end;
    margin-right: 5em;
    padding-top: 2em;
}

/* .logo-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;
    height: 100%;
    width: 100%;
} */

.logo-container img {
    width: 411px;
    height: 367px;
    border: 1px solid rgba(0, 0, 0, 1);
    border-radius: 30px;
    box-sizing: border-box;
}

.menu-container {
    padding-top: 30px;
    border-color: transparent !important;
}

.dropdown-toggle {
    align-items: center;
    justify-content: center;
    width: 363px;
    border-color: none !important;
    background-color: hsl(4, 77%, 47%) !important;
}

.dropdown-menu {
    background-color: hsl(4, 77%, 47%) !important;
    border-color: transparent !important;
    width: 363px;
    align-items: center;
}

.dropdown-item {
    background-color: hsl(4, 77%, 47%) !important;
    color: white !important;
    align-items: center;
}


.dropdown-item Button:hover,
.dropdown-item Button:focus,
.dropdown-item Button:active {
    background-color: rgb(235, 88, 88) !important;
    color: white !important;
    border: 1px solid rgb(235, 88, 88) !important;
}

.proceed-btn {
    text-transform: uppercase;
    color: #B21309;
    background: #FFFFFF;
    border: 1px solid #B31309;
    box-sizing: border-box;
    border-radius: 3px;
    /* display: flex; */
    margin-left: 75%;
    margin-top: 20%;
    /* margin-bottom: 25%; */
}

.btn:focus, .btn-secondary:focus, .btn-check:focus {
    box-shadow: none !important;
}
.validity-error {
    color: #dc3444;
    text-transform: capitalize;
    font-size: 11px;
}

.gift-icon {
  color:#dc3444;
  width: 50px;
  margin-top: -35px;
  margin-left: 55px;
}
.gift-text {
  color:#dc3444;
  font-size: 15px;
  margin-top: -10px;
}

.customer-table .elixir-table-wrapper {
  height: 66vh;
  overflow: hidden;
}

.item-commission select:disabled {
  opacity: 0 !important;
}
.details-cont {
    margin: 2rem;
    padding-top: 0;
}


.del-prompt-header-custom {
    background-color: rgb(213, 19, 19);
}

.del-prompt-icon {
    font-size: 5em;
}

.del-prompt-par {
    text-transform: none;
    font-weight: bold;
    margin-bottom: 0.5em;
}

.del-prompt-subpar {
    text-transform: none;
    margin-bottom: 0;
}

.free-label {
    display: inline-block;
    font-size: 8px;
}

.nc-modal-custom-radio {
    width: 10% !important;
}

/** Switch **/
.custom-control-input {
    margin-top: -5% !important;
    margin-left: 10%;
}

.form-check-input:checked {
    background-color: #e26358 !important;
    border: #e26358 !important;
}

.form-check-input:focus {
    outline: 0 !important;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    border-color: #e26358 !important;
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>") !important;
}

.modal-detail {
    text-transform: capitalize;
}

.modal-detail-small {
    font-style: italic;
    font-size: 12px;
}

.modal-detail-list {
    font-size: 14px;
}

.package-table .elixir-table-wrapper {
  height: 66vh;
  overflow: hidden;
}
.cont-section-prod-purchased-report .branch-report-row {
    padding: 0;
    margin-top: 90px;
}

.report-card {
    padding: 0;
    margin: 10px 0 0 0;
}

.report-card .btn-report-wrapper Button .report-card-text {
    font-size: 30px;
}

.report-card-text {
    margin: 0;
    color: black;
}

.report-card .btn-report-wrapper Button .report-card-subtext {
    font-size: 20px;
    margin-top: 10px;
    color: black;
}

.t-bold {
    font-weight: bold;
}

.cont-section-prod-purchased-report .branch-report-row .report-card .btn-report-wrapper Button {
    background: rgba(212, 38, 27, 0.2);
    border-radius: 20px;
    width: 227px;
    height: 254px;
    border: none;
}

.branch-report-row .report-card .btn-report-wrapper Button Button:hover,
.branch-report-row .report-card .btn-report-wrapper Button Button:focus,
.branch-report-row .report-card .btn-report-wrapper Button Button:active {
    background-color: rgba(233, 26, 11, 0.412);
}

h1 {
    margin-top: 30px;
}

.cont-sect-header {
    margin-top: 12px;
}

.products-loader {
    margin-left: 5vw;
}

.daily-products-table .elixir-table-wrapper {
    height: 60vh;
    overflow: hidden;
}